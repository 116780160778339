import Link from "next/link";
import Slider from "react-slick";

import { useRef } from "react";
import { useGetListingsQuery } from "../../features/api/api";
import { useSelector } from "react-redux";

import formatPrice from "../../utils/formatPrice";
import LoadingSpinner from "../common/LoadingSpinner";

const FeaturedProperties = () => {
  const dateRef = useRef(Date.now()).current;

  const { data, isFetching, isSuccess, isError, error } =
    useGetListingsQuery(dateRef);

  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    speed: 1000,
    infinite: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let content = [];
  // let content = listings?.slice(0, 12)?.map((item) => (

  if (isSuccess) {
    content = data.listings.slice(0, 8).map((item) => (
      <a className="item" key={item._id} href={`listing/${item._id}`}>
        <div className="feat_property ">
          <div className="thumb">
            {/* <img className="img-whp" src={item.img} alt="fp1.jpg" /> */}
            <img
              className="img-whp"
              style={{ maxHeight: "200px", objectFit: "cover" }}
              src={
                item.images && item.images.length
                  ? item.images[0].src
                  : "/assets/images/no-img/no-img-placeholder.png"
              }
              alt="fp1.jpg"
            />
            <div className="thmb_cntnt">
              <ul className="tag mb0">
                {/* {item.saleTag.map((val, i) => (
                  <li className="list-inline-item" key={i}>
                    <a href="#">{val}</a>
                  </li>
                ))} */}
              </ul>
              {/* End .tag */}

              {/* <ul className="icon mb0">
                <li className="list-inline-item">
                  <a href="#">
                    <span className="flaticon-transfer-1"></span>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="#">
                    <span className="flaticon-heart"></span>
                  </a>
                </li>
              </ul> */}
              {/* End .icon */}

              <Link href={`/listing/${item._id}`}>
                <a className="fp_price">{formatPrice(item.price)}</a>
              </Link>
            </div>
          </div>
          {/* End .thumb */}

          <div className="details">
            <div className="tc_content">
              {/* <p className="text-thm">{item.type}</p> */}
              <h4>
                <Link href={`/listing/${item._id}`}>
                  <a>{item.title}</a>
                </Link>
              </h4>
              <p>
                <span className="flaticon-placeholder"></span>
                &nbsp;
                {Object.values(item.location)
                  .filter((item) => item)
                  .join(", ") || "N/A"}
              </p>

              {/* <ul className="prop_details mb0">
                {item.itemDetails.map((val, i) => (
                  <li className="list-inline-item" key={i}>
                    <a href="#">
                      {val.name}: {val.number}
                    </a>
                  </li>
                ))}
              </ul> */}
            </div>
            {/* End .tc_content */}

            <div className="fp_footer">
              <ul className="fp_meta float-start mb0">
                <li className="list-inline-item">
                  {/* <Link href="/agent-v2">
                    <a>
                      <img src={item.posterAvatar} alt="pposter1.png" />
                    </a>
                  </Link> */}
                </li>
                <li className="list-inline-item">
                  <p>
                    {/* <a>{item.posterName}</a> */}
                    <a>
                      {item.user.fname} {item.user.lname}
                    </a>
                  </p>
                </li>
              </ul>
              {/* <div className="fp_pdate float-end">{item.postedYear}</div> */}
            </div>
            {/* End .fp_footer */}
          </div>
          {/* End .details */}
        </div>
      </a>
    ));
  }

  return (
    <>
      {isFetching ? (
        <LoadingSpinner size={50} />
      ) : isSuccess || (isError && error) ? (
        <Slider {...settings} arrows={false}>
          {content}
        </Slider>
      ) : null}
    </>
  );
};

export default FeaturedProperties;
