import CallToAction from "../common/CallToAction";
import CopyrightFooter from "../common/footer/CopyrightFooter";
import Footer from "../common/footer/Footer";
import MobileMenu from "../common/header/MobileMenu";
import Partners from "../common/Partners";
import Blogs from "../common/Blogs";
import FeaturedProperties from "./FeaturedProperties";
import FindProperties from "./FindProperties";
import Header from "./Header";
import Hero from "./Hero";
import WhyChoose from "../common/WhyChoose";
import PopupSignInUp from "../common/PopupSignInUp";
import SidebarMenu from "../common/header/dashboard/SidebarMenu";

import Link from "next/link";
import { ToastContainer, toast } from "react-toastify";

import { useSelector } from "react-redux";
import { useContext } from "react";
import UserContext from "../../features/user/UserContext";

const Index = () => {
  const { user } = useContext(UserContext);
  const { token } = useSelector((state) => state.auth);

  return (
    <>
      {/* <!-- Main Header Nav --> */}
      <Header />

      <Link href={user && token ? "/create-listing" : "/login"}>
        <a
          className="btn btn-block btn-lg btn-thm circle create-listing-popup"
          style={{
            position: "fixed",
            bottom: "20px",
            left: "20px",
            // transform: "translate(-50%, 0)",
            zIndex: 9998,
            width: "200px",
          }}>
          <span className="flaticon-plus"></span> Add Listing
        </a>
      </Link>

      {/* <!--  Mobile Menu --> */}
      <MobileMenu />

      {user && token && (
        <div className="dashboard_sidebar_menu">
          <div
            className="offcanvas offcanvas-dashboard offcanvas-start"
            tabIndex="-1"
            id="DashboardOffcanvasMenu"
            data-bs-scroll="true">
            <SidebarMenu />
          </div>
        </div>
      )}

      {/* <!-- Modal --> */}
      <PopupSignInUp toast={toast} />

      {/* <!-- Home Design --> */}
      <Hero />

      {/* <!-- Feature Properties --> */}
      <section id="feature-property" className="feature-property bgc-f7">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="main-title text-center mb40">
                <h2>Featured Businesses</h2>
                <p>Handpicked businesses by our team.</p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="feature_property_slider gutter-x15">
                <FeaturedProperties />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Property Cities --> */}
      {/* <section id="property-city" className="property-city pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="main-title text-center">
                <h2>Find Properties in These Cities</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <FindProperties />
          </div>
        </div>
      </section> */}

      {/* <!-- Why Chose Us --> */}
      <section id="why-chose" className="whychose_us pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="main-title text-center">
                <h2>Why Choose Us</h2>
                <p>We provide full service at every step.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <WhyChoose />
          </div>
        </div>
      </section>

      {/* <!-- Our Blog --> */}
      {/* <section className="our-blog bgc-f7 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="main-title text-center">
                <h2>Articles & Tips</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <Blogs />
          </div>
        </div>
      </section> */}

      {/* <!-- Our Partners --> */}
      {/* <section id="our-partners" className="our-partners bgc-f7 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="main-title text-center">
                <h2>Our Partners</h2>
                <p>We only work with the best companies around the globe</p>
              </div>
            </div>
          </div>
          <div className="row">
            <Partners />
          </div>
        </div>
      </section> */}

      {/* <!-- Start Call to Action --> */}
      {!token ? (
        <section className="start-partners bgc-thm pt50 pb50">
          <div className="container">
            <CallToAction />
          </div>
        </section>
      ) : null}

      {/* <!-- Our Footer --> */}
      <section className="footer_one">
        <div className="container">
          <div className="row justify-between max-lg:justify-center max-lg:gap-10">
            <Footer />
          </div>
        </div>
      </section>

      {/* <!-- Our Footer Bottom Area --> */}
      <section className="footer_middle_area">
        <div className="container">
          <CopyrightFooter />
        </div>
      </section>
    </>
  );
};

export default Index;
