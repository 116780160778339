import Router from "next/router";
import PricingRangeSlider from "./PricingRangeSlider";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import industries from "../../data/industries";

import {
  setCity,
  setIndustry,
  setPrice,
} from "../../features/search/searchSlice";
import { useSearchCitiesMutation } from "../../features/cities/citiesAPI";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

const GlobalFilter = ({ className = "" }) => {
  const [selectedCity, setSelectedCity] = useState(null); // type string
  const [selectedIndustry, setSelectedIndustry] = useState(null); // type string

  const dispatch = useDispatch();
  const [searchCities] = useSearchCitiesMutation();

  // submit handler
  const submitHandler = () => {
    const cityInput = document.querySelector("#globalFilterCity");
    const industryInput = document.querySelector("#globalFilterIndustry");

    if (
      [selectedCity, selectedIndustry].some(
        (filterItem) => filterItem && filterItem.length
      )
    ) {
      if (selectedCity) {
        if (selectedCity.includes(", ")) {
          let location = selectedCity.split(", ");
          dispatch(setCity(location[0]));
        } else {
          dispatch(setCity(selectedCity));
        }
      }

      if (selectedIndustry) {
        dispatch(setIndustry(selectedIndustry));
      }

      [cityInput, industryInput].forEach((input) => {
        if (input) {
          input.classList.remove("invalid");
        }
      });

      Router.push("/listing/search");
    } else {
      [cityInput, industryInput].forEach((input) => {
        if (input) {
          input.classList.add("invalid");
        }
      });
    }
  };

  const asyncLoadCities = async (inputValue, callback) => {
    try {
      const args = { name: inputValue };

      let citiesRes = [];

      const res = await searchCities(args);

      citiesRes = res.data.cities.map((item) => ({
        value: item.name,
        label: item.name,
      }));

      return citiesRes;
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  useEffect(() => {
    dispatch(setCity(null));
    dispatch(setIndustry(null));
    dispatch(setPrice({ min: 50000, max: 50000000 }));
  }, []);

  return (
    <div className={`home1-advnc-search ${className}`}>
      <ul className="h1ads_1st_list mb0">
        <li className="list-inline-item">
          <div className="form-group" id="globalFilterCity">
            <AsyncSelect
              classNames={{ container: () => "form-control" }}
              styles={{
                indicatorsContainer: () => ({ display: "none" }),
                control: () => ({}),
                placeholder: () => ({
                  fontStyle: "italic",
                  color: "black",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  padding: 0,
                  fontSize: "14px",
                }),
                singleValue: () => ({
                  color: "black",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  // padding: "17px",
                  fontSize: "14px",
                }),
                valueContainer: () => ({ paddingLeft: 0, paddingTop: "7px" }),
              }}
              defaultOptions
              cacheOptions
              isClearable
              value={
                selectedCity
                  ? { label: selectedCity, value: selectedCity }
                  : null
              }
              onChange={(opt) =>
                // dispatch(setCity(opt && "label" in opt ? opt.label : null))
                setSelectedCity(opt && "label" in opt ? opt.label : null)
              }
              key={selectedCity}
              loadOptions={asyncLoadCities}
              placeholder="City"
              menuShouldScrollIntoView={true}
            />
          </div>
        </li>
        {/* End li */}

        <li className="list-inline-item">
          <div className="form-group" id="globalFilterIndustry">
            <Select
              classNames={{ container: () => "form-control" }}
              styles={{
                indicatorsContainer: () => ({ display: "none" }),
                control: () => ({}),
                placeholder: () => ({
                  fontStyle: "italic",
                  color: "black",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  padding: 0,
                  fontSize: "14px",
                }),
                singleValue: () => ({
                  color: "black",
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  // padding: "17px",
                  fontSize: "14px",
                }),
                valueContainer: () => ({ paddingLeft: 0, paddingTop: "7px" }),
              }}
              placeholder="Industry"
              isClearable
              options={industries}
              value={
                selectedIndustry
                  ? { label: selectedIndustry, value: selectedIndustry }
                  : null
              }
              // onChange={(opt) => dispatch(setIndustry(opt ? opt.label : null))}
              onChange={(opt) => setSelectedIndustry(opt ? opt.label : null)}
            />
          </div>
        </li>
        {/* End li */}

        <li className="list-inline-item">
          <div className="small_dropdown2" style={{ display: "block" }}>
            <div
              id="prncgs"
              className="btn dd_btn"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
              style={{ display: "block" }}>
              <span className="italic">Price</span>
              <label htmlFor="InputEmail2">
                <span className="fa fa-angle-down"></span>
              </label>
            </div>
            <div className="dd_content2 dropdown-menu">
              <div className="pricing_acontent">
                <PricingRangeSlider />
              </div>
            </div>
          </div>
        </li>
        {/* End li */}

        <li className="list-inline-item">
          <div className="search_option_button" style={{ display: "block" }}>
            <button
              onClick={submitHandler}
              type="submit"
              className="btn btn-thm"
              style={{ display: "block" }}>
              Search
            </button>
          </div>
        </li>
        {/* End li */}
      </ul>
    </div>
  );
};

export default GlobalFilter;
