import GlobalHeroFilter from "../common/GlobalHeroFilter";

const HeroFilter = () => {
  return (
    <div className="home_content">
      <div className="home-text text-center">
        <h2 className="fz55">Buy & Sell businesses with a Click.</h2>
        {/* <p className="fz18 color-white">We offer you the best.</p> */}
      </div>
      {/* End .home-text */}

      <GlobalHeroFilter />
    </div>
  );
};

export default HeroFilter;
