module.exports = [
  {
    id: 1,
    img: "/assets/images/blog/1.jpg",
    postMeta: "business",
    title: `Skills That You Can Learn In The Real Estate Market`,
    posterAvatar: "/assets/images/property/pposter1.png",
    posterName: "Ali Tufan",
    postedDate: "7 August 2022",
    postDescriptions: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio neque. Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis, at malesuada orci congue. Nullam tempus sollicitudin cursus. Ut et adipiscing erat. Curabitur this is a text link libero tempus congue.`,
  },
  {
    id: 2,
    img: "/assets/images/blog/2.jpg",
    postMeta: "Construction",
    title: `Bedroom Colors You will Never this
    Regret`,
    posterAvatar: "/assets/images/property/pposter2.png",
    posterName: "Ali Tufan",
    postedDate: "7 August 2022",
    postDescriptions: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio neque. Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis, at malesuada orci congue. Nullam tempus sollicitudin cursus. Ut et adipiscing erat. Curabitur this is a text link libero tempus congue.`,
  },
  {
    id: 3,
    img: "/assets/images/blog/3.jpg",
    postMeta: "business",
    title: `5 Essential Steps for Buying everyone Investment`,
    posterAvatar: "/assets/images/property/pposter3.png",
    posterName: "Ali Tufan",
    postedDate: "7 August 2022",
    postDescriptions: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio neque. Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis, at malesuada orci congue. Nullam tempus sollicitudin cursus. Ut et adipiscing erat. Curabitur this is a text link libero tempus congue.`,
  },
  {
    id: 4,
    img: "/assets/images/blog/4.jpg",
    postMeta: "Construction",
    title: `Redfin Ranks the Most Competitive Neighborhoods of 2022`,
    posterAvatar: "/assets/images/property/pposter4.png",
    posterName: "Ali Tufan",
    postedDate: "7 August 2022",
    postDescriptions: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio neque. Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis, at malesuada orci congue. Nullam tempus sollicitudin cursus. Ut et adipiscing erat. Curabitur this is a text link libero tempus congue.`,
  },
  {
    id: 5,
    img: "/assets/images/blog/5.jpg",
    postMeta: "Construction",
    title: `Redfin Ranks the Most Competitive Neighborhoods of 2022`,
    posterAvatar: "/assets/images/property/pposter1.png",
    posterName: "Ali Tufan",
    postedDate: "7 August 2022",
    postDescriptions: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio neque. Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis, at malesuada orci congue. Nullam tempus sollicitudin cursus. Ut et adipiscing erat. Curabitur this is a text link libero tempus congue.`,
  },
  {
    id: 6,
    img: "/assets/images/blog/6.jpg",
    postMeta: "Construction",
    title: `Redfin Ranks the Most Competitive Neighborhoods of 2022`,
    posterAvatar: "/assets/images/property/pposter2.png",
    posterName: "Ali Tufan",
    postedDate: "7 August 2022",
    postDescriptions: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio neque. Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis, at malesuada orci congue. Nullam tempus sollicitudin cursus. Ut et adipiscing erat. Curabitur this is a text link libero tempus congue.`,
  },
  {
    id: 7,
    img: "/assets/images/blog/7.jpg",
    postMeta: "Construction",
    title: `Redfin Ranks the Most Competitive Neighborhoods of 2022`,
    posterAvatar: "/assets/images/property/pposter3.png",
    posterName: "Ali Tufan",
    postedDate: "7 August 2022",
    postDescriptions: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio neque. Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis, at malesuada orci congue. Nullam tempus sollicitudin cursus. Ut et adipiscing erat. Curabitur this is a text link libero tempus congue.`,
  },
  {
    id: 8,
    img: "/assets/images/blog/8.jpg",
    postMeta: "Construction",
    title: `Redfin Ranks the Most Competitive Neighborhoods of 2022`,
    posterAvatar: "/assets/images/property/pposter4.png",
    posterName: "Ali Tufan",
    postedDate: "7 August 2022",
    postDescriptions: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio neque. Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis, at malesuada orci congue. Nullam tempus sollicitudin cursus. Ut et adipiscing erat. Curabitur this is a text link libero tempus congue.`,
  },
  {
    id: 9,
    img: "/assets/images/blog/9.jpg",
    postMeta: "Construction",
    title: `Redfin Ranks the Most Competitive Neighborhoods of 2022`,
    posterAvatar: "/assets/images/property/pposter1.png",
    posterName: "Ali Tufan",
    postedDate: "7 August 2022",
    postDescriptions: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mollis et sem sed sollicitudin. Donec non odio neque. Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan nec. Quisque bibendum orci ac nibh facilisis, at malesuada orci congue. Nullam tempus sollicitudin cursus. Ut et adipiscing erat. Curabitur this is a text link libero tempus congue.`,
  },
];
