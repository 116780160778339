/* eslint-disable @next/next/no-img-element */
import listabiz_confidentiality from "../../../public/assets/images/home/why_choose_us/confidentiality-black.png";
import listabiz_confidentiality_hover from "../../../public/assets/images/home/why_choose_us/confidentiality-black-hover.png";
import listabiz_network from "../../../public/assets/images/home/why_choose_us/network-black.png";
import listabiz_network_hover from "../../../public/assets/images/home/why_choose_us/network-black-hover.png";
import { useState } from "react";

const Hoverable = ({ item = {}, style = "" }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      className="col-md-6 col-lg-4 col-xl-4"
      key={item.id}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}>
      <div className={`why_chose_us ${style} bgc-f7 `}>
        <div className="icon">
          <span className={`${item.icon} flex items-center justify-center`}>
            {item.iconPath && (
              <img
                src={!hover ? item.iconPath.src : item.iconPathHover.src}
                alt=""
                style={{
                  width: "80%",
                  height: "80%",
                  "&:hover": { borderColor: "white" },
                }}
              />
            )}
          </span>
        </div>
        <div className="details">
          <h4>{item.title}</h4>
          <p>{item.descriptions}</p>
        </div>
      </div>
    </div>
  );
};

const WhyChoose = ({ style = "" }) => {
  const whyCooseContent = [
    {
      id: 1,
      icon: "flaticon-high-five",
      iconPath: "",
      title: "Trust & Credibility",
      descriptions: `The team and it’s advisors have decades of expertise in banking, audit, law, & heading multi-million dollar businesses.`,
    },
    {
      id: 2,
      // icon: "flaticon-home-1",
      icon: "",
      title: "Access to a wide network",
      iconPath: listabiz_network,
      iconPathHover: listabiz_network_hover,
      descriptions: `LiztaBiz has cultivated an expansive network of trusted partners, both within and outside our organization, ensuring a robust and reliable source for finding and matching businesses with the right buyers and sellers.`,
    },
    {
      id: 3,
      // icon: "flaticon-profit",
      icon: "",
      title: "Confidentiality & Professionalism",
      iconPath: listabiz_confidentiality,
      iconPathHover: listabiz_confidentiality_hover,
      descriptions: `Exemplifying Confidentiality and Professionalism Every transaction handled by our team adheres to strict protocols, ensuring meticulous documentation and agreements facilitated by our qualified team of professionals.`,
    },
  ];

  return (
    <>
      {whyCooseContent.map((item) => (
        <Hoverable item={item} style={style} key={item.id} />
      ))}
    </>
  );
};

export default WhyChoose;
